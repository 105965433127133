/* Admin Contact Container */
.admin-contact-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  min-height: 100vh;
  position: relative;
  /* background-image: url('../new.jpg');  */
  background-repeat: no-repeat;
  background-size: cover;
  color:black;
  background-position: center;
}
.download {
  border: 1px solid;
  background-color: goldenrod;
  display: inline-block;
  color: white;
  font-size:1rem;
  font-weight: bold;
  text-decoration: none;
  padding: 15px 40px;
  border-radius: 7px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.1s ease,
              box-shadow 0.2s ease,
              background-color 0.3s ease; /* Add time unit here */
  position: relative;
  animation: glow 0.5s infinite alternate;
}

.download:hover {
  transform: scale(1.05);
  background-color: #FFC107;
  box-shadow: 0 6px 15px rgba(255, 215, 0, 0.8),
              0 0 30px rgba(255, 215, 0, 0.5);
}

@keyframes glow {
  0% {
    box-shadow: 0 9px 10px rgba(255, 217, 0, 0.762),
                0 0 10px rgba(255, 215, 0, 0.2);
  }
  100% {
    box-shadow: 0 9px 20px rgba(255, 217, 0, 0.788),
                0 0 20px rgba(255, 217, 0, 0.877),
                0 0 40px rgba(255, 215, 0, 0.4);
  }
}

.download:active {
  transform: scale(0.98);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}



/* Admin Contact Section */
.admin-contact-section {
  margin-bottom: 30px;
}

.admin-phone {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Contact Buttons */
.admin-contact-buttons {
  display: flex;
  gap: 15px;
}

.contact-button {
  padding: 12px 25px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  display: flex;
  align-items: center;
  animation: pulse 2s infinite;
}

/* Button Colors */
#call-button {
  background-color: #4caf50; /* Green color for Call */
}

#whatsapp-button {
  background-color: #25d366; /* WhatsApp green */
}

/* Hover Effects for Contact Buttons */
.contact-button:hover {
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow */
}

/* Icon Spacing */
.contact-button i {
  margin-right: 10px;
}

/* Pulse Effect for Buttons */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  }
}

/* Download Section */
.download-section {
  margin-top: 20px;
}

.download-text {
  font-size: 28px;
  margin-bottom: 10px;
}

/* .download-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;

} */

/* Enhanced Download Button Styles */
/* .download {
  padding: 15px 30px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  animation: pulseDownload 3s infinite;
} */

/* Pulse Animation for Download Button */
@keyframes pulseDownload {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 123, 255, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 123, 255, 0.4);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 123, 255, 0);
  }
}

/* Hover Effects for Download Button */
  /* .download:hover {
  background: linear-gradient(45deg, #0056b3, #004494);
  transform: translateY(-3px);
  box-shadow: 0 8px 12px rgba(0, 123, 255, 0.4);
  animation: bounce 0.4s ease-out;
}   */

/* Bounce Effect on Hover */
/* @keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
} */

/* Image Section */
.image-section {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.admin-image {
  max-width: 100%;
  height: auto;
}

/* Responsive Design for Tablet Screens */
@media (max-width: 1440px) {
  .admin-contact-container {
    align-items: center;
    text-align: center;
    padding-left: 0;
    margin-left: 0;
  }

  .admin-contact-buttons {
    flex-direction: row;
    gap: 10px;
  }

  .contact-button {
    padding: 10px 20px;
    font-size: 16px;
  }

  .download-buttons {
    flex-direction: row;
    gap: 10px;
  }

  .image-section {
    top: auto;
    right: auto;
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .admin-image {
    max-width: 50%;
    max-height: 200px;
  }
}

/* Extra Small Screens (Phones) */
@media (max-width: 480px) {
  .admin-contact-container {
    align-items: center;
    text-align: center;
    padding-left: 0;
    margin-left: 0;
  }

  .admin-contact-buttons {
    flex-direction: row;
    gap: 10px;
  }

  .contact-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .download-buttons {
    flex-direction: row;
    gap: 10px;
  }

  .image-section {
    top: auto;
    right: auto;
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .admin-image {
    max-width: 50%;
    max-height: 150px;
  }
}



body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    letter-spacing: 0;
    font-family: 'Rubik';
    /* background-color: #f8f7fa; */
    color: white;
}
a,
.btn {
    transition: all 0.3s ease-in-out;
}
a:hover {
    text-decoration: none;
}
.hero-text-sec .row {
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.hero-text-sec {
    padding: 40px 0px;
    justify-content: space-between;
    height: 89%;
    align-items: center;
    width: 100%;
    display: flex;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0
}
.mobile-num {
    transition: 0.3s;
    display: flex;
    color: #fff;
    background: #200040;
    height: 60px;
    font-size: 18px;
    padding: 0px 5px;
    padding-right: 20px;
    border: 1px solid #ffffff57;
    border-radius: 103px;
    align-items: center;
}
.mobile-num:hover {
    color: #fff;
}
.mobile-num-img {
    position: relative;
    color: #fff;
    background: radial-gradient(ellipse farthest-corner at right bottom, #eeac03 0%, #daa526 8%, #9f7928 30%, #976800 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #dca216 8%, #e7a90b 25%, #e1a511 62.5%, #5d4a1f 100%);
    width: 46px;
    height: 46px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    margin-right: 10px;
}
.mobile-num-img img {
    filter: invert(1);
    width: 25px;
}
.whatsapp-num-img img {
    filter: invert(1);
    width: 25px;
}
.whatsapp-num-img {
    position: relative;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    margin-right: 3px;
}
.contact span {
    font-weight: 600;
}
.contact-info{
    background-color: darkcyan !important;
}
.contact-num-info {
    max-width: 345px;
}
.whatsaap-info,.contact-info {
    transition: 0.3s;
    display: flex;
    color: #fff;
    border: 1px solid #ffffff57;
    background: #44c054;
    height: 50px;
    font-size: 18px;
    justify-content: center;
    padding: 0px 20px;
    border-radius: 10px;
    align-items: center;
}
.contact a:hover {
    color: #fff;
}
.number-info:hover{
    text-decoration: underline;
}
.number-info {
    font-size: 31px;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    color: #008b8b;
}
.contact {
    display: flex;
    text-align: center;
    column-gap: 18px;
    width: max-content;
    position: relative;
    margin-bottom: 38px;
    z-index: 1;
    border-radius: 13px;
    justify-content: center;
}
.hero-section {
background-repeat: no-repeat;
align-items: center;
justify-content: center;
min-height: 100vh;
background-position: center center;
}
.hero-img-right img {
    width: 100%;
}
.container {
    max-width: 1440px;
}
.header .row {
    justify-content: end;
    align-items: center;
    padding: 6px;
    padding-bottom: 0px;
}
.small-btn {
    font-size: 19px;
    display: inline-flex;
    padding: 12px 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 10px;
    align-items: center;
    background: radial-gradient(ellipse farthest-corner at right bottom, #eeac03 0%, #daa526 8%, #9f7928 30%, #976800 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #dca216 8%, #e7a90b 25%, #e1a511 62.5%, #5d4a1f 100%);
    border: 1px solid #fff;
    gap: 6px;
    position: relative;
    animation: shadowPulse 1s infinite;
}
@keyframes shadowPulse {
    0% {
        box-shadow: 0 0 0 0px rgb(157 118 33);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(82, 221, 103, 0);
    }
}
.small-btn:hover {
    color: #fff;
    transform: translate(0, -5px);
}
.small-btn:hover img {
    filter: none;
}
.small-btn img {
    width: 28px;
    margin-right: 5px;
}
.hero-text h1 {
    margin-bottom: 40px;
    color: #000;
    font-size: 64px;
    line-height: 78px;
    font-weight: 600;
}
.section-title {
    text-align: center;
}
.header {
    position: absolute;
    right: 34px;
}
.header-menu ul {
    margin: 0px -20px;
}
.header-menu ul li {
    display: inline-block;
    padding: 0px 20px;
}
.hero-text h5 {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    margin-bottom: 15px;
}
.hero-img-inner img {
    max-width: 567px;
}

.hero-img-inner {
    text-align: right;
}
.text-box p {
    font-size: 18px;
    color: #383838;
    font-weight: 500;
    line-height: 30px;
}
.hero-text {
    padding-left: 164px;
    width:50% ;  
}
.hero-img-right{
    width: 50%;
}

@media(max-width:1400px){
    .hero-text {
        padding-left: 34px;
    }
    .hero-text-sec {
        height: 105%;
    }
}

@media(max-width:1200px){
    .hero-text {
        padding-left: 34px;
    }
    .hero-text-sec {
        height: 100%;
    }
}

@media(max-width:992px){
    .hero-text h1 {
        margin-bottom: 40px;
        color: #000;
        font-size: 52px;
        line-height: 55px;
    }
}

@media(max-width:768px){
    .hero-text h1 {
        margin-bottom: 20px;
        color: #000;
        font-size: 32px;
        line-height: 40px;
    }
}

@media(max-width:575px){
    .hero-text h1 {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 20px;
    }
    .whatsapp-num-img img {
        filter: invert(1);
        width: 17px;
    }
    .hero-text-sec{
        justify-content: center;
    }
    .contact span {
        font-weight: 600;
        font-size: 12px;
    }
    .hero-text {
        padding-left: 0;
    }
    .contact-num-info{
        max-width: auto;
    }
    .hero-text-sec {
        padding: 11px 0px;
        height: auto;
    }
    .header .row{
        justify-content: left;
    }
    .hero-section{
        background:white;
        display: flex;
        padding-left:1rem ;
    }
    .header {
        position: absolute;
        right: 22%;
        top: 0;
    }
    .small-btn{
        padding: 8px 9px;
        font-size: 13px;
    }
    .whatsaap-info, .contact-info{
        height: 32px;
        justify-content: center;
        padding: 0px 7px;
        border-radius: 7px;
    }
    .contact{
        margin-bottom: 10px;
        column-gap: 5px;
    }
    .number-info{
        font-size: 17px;
        font-weight: 500;
        justify-content: left;
        padding-bottom: 10px;
    }
}
